var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"ResetPassword"}},[_c('AppContainer',{attrs:{"use-background":true}},[_c('HeaderCommon',{attrs:{"use-logo":true},scopedSlots:_vm._u([{key:"headerButton",fn:function(){return [_c('ButtonBack')]},proxy:true}])}),_c('HeaderResetPassword',{attrs:{"title":"パスワードを再設定する","text-tutorial":"新しいパスワードを入力して、パスワードのリセットを完了します。"}}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form f-w6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"新しいパスワード","rules":{
            required: true,
            min: 6,
            max: 25
          },"vid":"confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',{staticClass:" text-left"},[_c('label',{staticClass:"f-w6"},[_vm._v("新しいパスワード")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.new_password),expression:"data.new_password"}],staticClass:"f-w3 text",attrs:{"type":"password"},domProps:{"value":(_vm.data.new_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "new_password", $event.target.value)}}}),_c('span',{staticClass:"error f-w3"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"confirmed:confirmPassword|required","name":"新しいパスワード"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',{staticClass:"password text-left"},[_c('label',{staticClass:"f-w6"},[_vm._v("新しいパスワード（確認）")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.confirm_password),expression:"data.confirm_password"}],staticClass:"f-w3 text",attrs:{"type":"password"},domProps:{"value":(_vm.data.confirm_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "confirm_password", $event.target.value)}}}),_c('span',{staticClass:"error f-w3"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('button',{staticClass:"f-w3"},[_vm._v(" 登録する ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }