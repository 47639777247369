<template>
  <div id="ResetPassword">
    <AppContainer :use-background="true">
      <HeaderCommon :use-logo="true">
        <template v-slot:headerButton>
          <ButtonBack />
        </template>
      </HeaderCommon>
      <HeaderResetPassword
        title="パスワードを再設定する"
        text-tutorial="新しいパスワードを入力して、パスワードのリセットを完了します。"
      />
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)" class="form f-w6">
          <ValidationProvider
            name="新しいパスワード"
            :rules="{
              required: true,
              min: 6,
              max: 25
            }"
            v-slot="{ errors }"
            vid="confirmPassword"
          >
            <div class=" text-left">
              <label class="f-w6">新しいパスワード</label>
              <input
                type="password"
                class="f-w3 text"
                v-model="data.new_password"
              />
              <span class="error f-w3">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider
            rules="confirmed:confirmPassword|required"
            name="新しいパスワード"
            v-slot="{ errors }"
          >
            <div class="password text-left">
              <label class="f-w6">新しいパスワード（確認）</label>
              <input
                type="password"
                class="f-w3 text"
                v-model="data.confirm_password"
              />
              <span class="error f-w3">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <button class="f-w3">
            登録する
          </button>
        </form>
      </ValidationObserver>
    </AppContainer>
  </div>
</template>

<script>
import HeaderResetPassword from "@/components/HeaderResetPassword";
import AppContainer from "@/views/Common/AppContainer";
import HeaderCommon from "@/views/Common/HeaderCommon";
import ButtonBack from "@/views/Common/ButtonBack";
export default {
  name: "ResetPassword",
  components: {
    HeaderResetPassword,
    AppContainer,
    HeaderCommon,
    ButtonBack
  },
  data() {
    return {
      data: {
        new_password: "",
        confirm_password: ""
      }
    };
  },
  methods: {
    async onSubmit() {
      await this.$store
        .dispatch("resetPassword/resetPassword", {
          token:
            this.$route.params.token || localStorage.getItem("token_reset"),
          newPassword: this.data.new_password,
          confirmPassword: this.data.confirm_password
        })
        .then(() => {
          this.$toast("パスワードのリセットに成功", "成功", "success");
          this.$root.$refs.loading.start();
          this.$router.push({ name: "LoginEmail" });
          this.$root.$refs.loading.finish();
          localStorage.removeItem("token_reset");
        })
        .catch(error => {
          if (error.response.data.error.code === "5022") {
            this.$toast(
              "認証コードの有効期限が過ぎています。",
              "不合格",
              "danger"
            );
            this.$router.push({ name: "ResetPasswordByEmail" });
            this.$root.$refs.loading.finish();
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/common/resetPassword.scss";
</style>
